<template xmlns="http://www.w3.org/1999/html">
  <div class="content" >
    <article
      class="row"
      style="margin-top: 10px; background: linear-gradient(#1d1d1d, black)"
    >

      <vue-whatsapp-widget
        phoneNumber="+524421867547"
        companyName="tulum.party"
        textReply="Typically replies within 10-15 mins (9am - 10pm)"
        companyLogo="https://imgix.cosmicjs.com/08f3cb30-e877-11ec-9177-7506dda64181-2022-06-09-23.37.07.jpg?h=50"
      />
      <section class="text-center col-lg-8 col-sm-12">
        <HeadImage :image="mainImage" align="text-center" title=" Cancun Clubs"
                   caption="The gateway to the Riviera Maya Experience"></HeadImage>
        <p class="text-center">

          <strong> Seize the opportunity to experience Cancun</strong>. From immaculate blue beaches to world-class dining, Cancun has it all. <br>
          <br>
          Experience the energy of Cancun's world-class clubs, bars, and live music venues.

          <br>
          Whats hot this <strong class="label label-info font-weight-700">{{
              getCurrentMonth
            }}</strong>
        </p>


        <section>
          <div class="card text-center" style="border: 2px solid black;margin-top: 30px">
            <h3
              class="tulum-party-subtitle"
              style="
                text-transform: uppercase;
                font-size: 1.1rem;
                letter-spacing: 0.1rem;
                font-weight: bold;
              "
            >
              <br/>
               In Cancun & Want To Have Fun?
            </h3>
            <h5 style="margin-top: -30px">
                We'll tell you how
            </h5>
            <br/>
            <RsvpViaDialog></RsvpViaDialog>
            <br/>
            <small style="color: aquamarine">
              We operate from 9AM - 10PM / Answer within 15 mins</small
            >
            <br/>
            <small>NO EXTRA FEE</small>
          </div>


        </section>

        <section class="row" style="margin-top: 80px">

          <br>
          <br>

          <h4 class="section-header" style="font-size: 1.4rem">NIGHT OPTIONS</h4>
          <br>
          <br>
          <div class="card text-center" style="border: 2px solid black">
            <p>Cancun Top Festive Clubs</p>
          </div>
          <br>
          <br>
          <br>

          <card
            class="card text-center col-sm-12 col-lg-12"
            v-for="(row, idx) in venues"
            :key="idx"
            style="box-shadow: #000000 10px 10px 2px; padding: 40px"
          >
            <template slot="header">

              <router-link :to="{ name: 'CancunClub', params: { slug: row.slug } }">
                <ResponsiveImage :image="row.metadata.main_image.imgix_url"
                                 class="card-animation-on-hover"></ResponsiveImage>
                <img
                  v-lazy="clubsConfig[row.slug]['logo']+'?w=50&h=50&fit=clamp'"
                  height="50px"
                  width="50px"
                  style="border-radius: 300px;margin-top: 20px;display: inline"
                />
                <h4 class="tulum-party-title" style="margin-top: 20px;font-size: 1.7rem">
                  {{ row.title }}
                </h4>


                <p style="margin-top: -20px;font-size: 0.8rem">{{ clubsConfig[row.slug]["caption"] }}</p>

                <hr class="brand-title-black" style="height: 1px"/>
              </router-link>
              <router-link
                :to="{ name: 'CancunClub', params: { slug: row.slug } }"
                class="badge-pill"
                style="
              background-color: #23d5ab;
              margin-bottom: 5px;
              color: black !important;
            "
                v-if="incomingClubParties[row.title]"
              >{{ incomingClubParties[row.title].length }} Upcoming Events
              </router-link>
              <br v-if="incomingClubParties[row.title]"/>
              <hr class="brand-title-black" style="height: 4px"/>
              <div
                v-html="row.metadata.description"
                class="md-small-hide"
                style="color: #fff !important; margin-bottom: 10px"
              ></div>
              <br/>
              <!--          <ClubDetails :config="ClubTagsConfig[row.slug]"></ClubDetails>-->
            </template>

            <template slot="default">
              <router-link
                :to="{ name: 'CancunClub', params: { slug: row.slug } }"
                class="btn font-weight-bold btn-primary"
                style="margin-top: 40px"
              >
                INFO & EVENTS
              </router-link>
            </template>
          </card>

        </section>
      </section>
      <aside class="card col-4 col-lg-4 d-none d-lg-inline">

        <SmartMenu :links="links" :logo="this.mainImage" ctaText="DROP US A LINE" top-section-name="Have Fun">
          <div class="row">
            <div class="col-sm-12 text-center">
              <p><strong>Feeling Lost?</strong></p>
              <p>
                We Are Your Single Contact for All Clubs & Events In The Riviera Maya.
              </p>
              <hr>
            </div>
          </div>
        </SmartMenu>

      </aside>

    </article>
  </div>
</template>

<script>
import ClubDetails from '@/components/Clubs/ClubDetailsTable';
// import ClubFilter from "@/components/ClubFilter";
import clubsTableConfig from '../../common/cancunClubsTableConfig';
import HeadImage from "@/components/HeadImage/HeadImage";
import SmartMenu from "@/components/SmartMenu/SmartMenu";
import ResponsiveImage from "@/components/HeadImage/ResponsiveImage";
import clubsConfig from '../../common/cancunClubsConfig';

const RsvpViaDialog = () =>
  import(
    /* webpackChunkName: "RsvpDialogs" */ '../../components/Reservations/RsvpViaDialog'
    );
const VueWhatsappWidget = () =>
  import(
    /* webpackChunkName: "vue-whatsapp-widget" */ '../../components/WhatsAppWidget/Widget'
    );

export default {
  name: 'CancunClubs',
  data: () => {
    return {
      showAll: false,
      showPalms: false,
      showModal: true,
      ClubTagsConfig: {tags: []},
      clubsConfig: [{}],
      bgPalm:
        'https://imgix.cosmicjs.com/d215fe40-8144-11ee-9ebe-eb22b0517bf0-IMG0515.PNG',
      clubsSpirt:
        'https://imgix.cosmicjs.com/7b95aff0-7b8f-11ec-8c2f-a300cf8a78dc-image.png',
      activityImg:
        'https://imgix.cosmicjs.com/3f6794f0-6cfe-11ee-a874-d142b6892265-activity.png',
      marcianoRosa:
        'https://imgix.cosmicjs.com/4701cb20-b87f-11ec-a7cc-25c8f0ace24d-marciano-rosa.png',
      mainImage:
      'https://imgix.cosmicjs.com/b3075b10-84dc-11ef-b5a0-93db72e2be98-444972260_984337643696796_499812968371281100_n.jpg',
      beachImage: 'https://imgix.cosmicjs.com/2513afe0-f18e-11ec-8fb8-5d396858ac9b-IMG7503.jpg',
      questionClub: '',
      showReserve: false,
      questionType: 'info',
    };
  },
  metaInfo: () => ({
    title:
      "Cancun's Top Clubs: Book Your Night Out",
    link: [{rel: 'canonical', href: 'https://www.tulum.party/cancun-clubs'}],
    meta: [
      {
        name: 'description',
        content:
          'Discover the best clubs in Cancun and book your reservations with ease. Our team of party experts will guide you to the hottest spots.',
      },
      {
        name: 'keywords',
        content:
          'cancun nightclubs, cancun beach clubs, cancun nightlife, cancun dj events, cancun party scene, cancun music venues, cancun dance clubs, cancun bars, cancun clubs calendar, cancun events calendar, cancun club reservations, cancun vip tables, cancun bottle service, cancun bachelorette party, cancun bachelor party, cancun vacation activities, cancun things to do at night',
      },
      {
        property: 'og:title',
        content: `Cancun's Top Clubs: Book Your Night Out`,
      },
      {property: 'og:type', content: 'website'},
      {property: 'og:locality', content: 'cancun'},
      {property: 'og:country-name', content: 'Mexico'},
      {
        property: 'og:description',
        content: `Cancun's Top Clubs: Book Your Night Out`,
      },
      {
        property: 'og:image',
        content:
          this.mainImage
      },
    ],
  }),
  components: {
    ResponsiveImage,
    SmartMenu,
    HeadImage,
    ClubDetails,
    clubsConfig,
    // ClubFilter,
    RsvpViaDialog,
    VueWhatsappWidget,
  },
  computed: {
    links: {
      get() {
        return (
          this.$store.state.links || []
        );
      },
    },
    incomingParties: {
      get() {
        return this.$store.state.incomingParties;
      },
    },
    incomingClubParties: {
      get() {
        return this.$store.state.incomingClubParties;
      },
    },
    venues: {
      get() {
        return this.$store.state.cancun_venues;
      },
    },
    categories: {
      get() {
        let h = {};
        this.$store.state.venues.forEach((venue) => {
          venue.metadata.tags.split('|').forEach((tag) => {
            if (h[tag]) {
              h[tag].push(venue.title);
            } else {
              h[tag] = [venue.title];
            }
          });
        });
        return h;
      },
      // return this.$store.state.venues.map(x => x.metadata.tags.split("|")).flat().filter((v,i,a) => a.indexOf(v) === i);
    },
    getCurrentMonth: {
      get() {
        let d = new Date();
        return (
          [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ][d.getMonth()] +
          ' ' +
          d.getFullYear()
        );
      },
    },
    sortedVenues: {
      get() {
        return [...this.$store.state.cancun_venues].sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
      },
    },
  },
  created() {
    // alert( Intl.DateTimeFormat().resolvedOptions().timeZone);
    this.$store.dispatch('getCancunClubs', {start: 0, limit: 16});
    // this.$store.dispatch('getParties');
    this.$store.dispatch('getLinks', {slug: 'cancun-clubs'});

    this.ClubTagsConfig = clubsTableConfig;
    this.clubsConfig = clubsConfig;

    window.history.scrollRestoration = 'manual';
  },
  updated() {
    // this.ClubTagsConfig = clubsTagsConfig;
    setTimeout(() => (this.showPalms = true), 5000);
  },
  methods: {
    paginate() {
      this.showAll = true;
      this.$store.dispatch('getCancunClubs', {start: 12, limit: 20});
    },
  },
};
</script>
